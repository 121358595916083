import React, { useState, useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";
import Seo from "../components/seo/seo";
import { SsyLink } from "../components/ssyLink/ssyLink";
import { RaisingBox } from "../components/raisingBox/raisingBox";
import { Layout } from "../components/layout/layout";
import style from "../assets/css/404.module.css";
import NotFoundImage from "../assets/images/samsaidyesNotFound.jpg";
const Page404 = (props) => {
  const intl = useIntl();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    setTimeout(() => {
      if (isSubscribed) {
        setIsLoaded(true);
      }
    }, 400);
    return () => {
      isSubscribed = false;
    };
  }, []);

  if (!isLoaded) {
    return <div></div>;
  }

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "HOME_META_TITLE" })}
        url={props.location.pathname}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        description={intl.formatMessage({ id: "HOME_META_DESCRIPTION" })}
      />
      {isLoaded && (
        <main>
          <div style={{ width: "100%" }}>
            <RaisingBox>
              <section className={style.container}>
                <img src={NotFoundImage} width={300} height={300} alt="not found page"/>
                <h1> {intl.formatMessage({ id: "NOT_FOUND_PAGE_TITLE" })}</h1>
                <p className={style.marginTop}>
                  {" "}
                  {intl.formatMessage({ id: "NOT_FOUND_PAGE_TEXT_1" })}
                </p>
                <p> {intl.formatMessage({ id: "NOT_FOUND_PAGE_TEXT_2" })}</p>
                <p className={style.marginBottom}>
                  {" "}
                  {intl.formatMessage({ id: "NOT_FOUND_PAGE_TEXT_3" })}
                </p>
                <div className={style.buttonContainer}>
                  <SsyLink
                    type="primary"
                    internal={false}
                    to={`${process.env.GATSBY_PUBLIC_URL}/${intl.formatMessage({
                      id: "MAIN_LANGUAGE",
                    })}/`}
                    label={intl.formatMessage({ id: "NOT_FOUND_PAGE_LINK" })}
                  />
                </div>
              </section>
            </RaisingBox>
          </div>
        </main>
      )}
    </Layout>
  );
};

export default Page404;
